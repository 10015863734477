



























































































































































import { SelectArPrepayment } from "@/components/ArPrepayment";
import SelectBranch from "@/components/custom/select/SelectBranch.vue";
import SelectCustomer from "@/components/custom/select/SelectCustomer.vue";
import SelectMasterType from "@/components/custom/select/SelectMasterType.vue";
import { APagination, useDate } from "@/hooks";
import useArPrepayment, { FormFilter } from "@/hooks/useArPrepayment";
import MNotificationVue from "@/mixins/MNotification.vue";
import { FIRST_PAGE } from "@/mixins/MQueryPage.vue";
import { Option } from "@/models/class/option.class";
import { RequestQueryParams } from "@/models/class/request-query-params.class";
import {
  DEFAULT_PAGE_SIZE,
  ONE,
  PAGE_SIZE_OPTIONS,
} from "@/models/constant/global.constant";
import { Pagination } from "@/models/constant/interface/common.interface";
import { DEFAULT_DATE_FORMAT } from "@/models/constants/date.constant";
import { InvoiceArPrepaymentStatusEnum } from "@/models/enums/ArPrepayment.enum";
import { InvoicePrepaymentListResponseDto } from "@interface/ar-prepayment";
import { BranchWarehouseResponseDto } from "@interface/branch";
import { ListContactDataDTO } from "@interface/ContactDataDto.interface";
import { RequestQueryParamsModel } from "@interface/http.interface";
import { FormModel } from "ant-design-vue";
import moment, { Moment } from "moment";
import { Component, Mixins, Ref } from "vue-property-decorator";
import { Route } from "vue-router";

const EXCLUDE_TYPE = "Invoice AR";

@Component({
  components: {
    SelectMasterType,
    SelectBranch,
    SelectCustomer,
    SelectArPrepayment,
  },
})
export default class IndexPage extends Mixins(MNotificationVue) {
  EXCLUDE_TYPE = EXCLUDE_TYPE;
  PAGE_SIZE_OPTIONS = PAGE_SIZE_OPTIONS;
  DEFAULT_DATE_FORMAT = DEFAULT_DATE_FORMAT;

  @Ref("formModel")
  formModel!: FormModel;

  pagination = {
    limit: +(this.$route.query?.l || DEFAULT_PAGE_SIZE),
    page: +(this.$route.query?.p || FIRST_PAGE),
    reset(): void {
      this.page = FIRST_PAGE;
    },
  };

  form: FormFilter = {
    invoiceType: "",
    branchName: "",
    customerName: "",
    invoiceDate: [],
    invoiceNumber: "",
    status: "" as InvoiceArPrepaymentStatusEnum,
  };

  columns = [
    {
      title: this.$t("lbl_invoice_number"),
      dataIndex: "invoiceNumber",
      scopedSlots: { customRender: "nullable" },
    },
    {
      title: this.$t("lbl_customer_name"),
      dataIndex: "customerName",
      scopedSlots: { customRender: "nullable" },
    },
    {
      title: this.$t("lbl_branch"),
      dataIndex: "branchName",
      width: 100,
      scopedSlots: { customRender: "nullable" },
    },
    {
      title: this.$t("lbl_invoice_date"),
      dataIndex: "invoiceDate",
      width: 150,
      scopedSlots: { customRender: "date" },
    },
    {
      title: this.$t("lbl_invoice_type"),
      dataIndex: "invoiceType",
      width: 150,
      scopedSlots: { customRender: "nullable" },
    },
    {
      title: this.$t("lbl_invoice_amount"),
      dataIndex: "amount",
      scopedSlots: { customRender: "currency" },
    },
    {
      title: this.$t("lbl_status"),
      dataIndex: "invoicePrepaymentStatus",
      width: 150,
      scopedSlots: { customRender: "nullable" },
    },
    {
      title: this.$t("lbl_action"),
      key: "action",
      scopedSlots: { customRender: "action" },
      width: 100,
      align: "center",
    },
  ];

  loading = false;

  dtPrepayment: Pagination<InvoicePrepaymentListResponseDto> = {
    currentPage: 0,
    data: [],
    totalElements: 0,
    totalPages: 0,
  };

  mounted(): void {
    this.form = this.mapQueryRouteToForm(this.$route.query);
    const params = this.buildParams(this.form);
    this.fetchData(params);
  }

  mapQueryRouteToForm(route: Route["query"]): FormFilter {
    const {
      branch = "",
      invoiceType = "",
      customer = "",
      from = "",
      to = "",
      invoiceNumber = "",
      status = "",
    } = route;

    const invoiceDate: Moment[] = [];
    if (from && to) {
      invoiceDate.push(moment(from.toString()), moment(to.toString()));
    }

    return {
      invoiceDate,
      status: status as InvoiceArPrepaymentStatusEnum,
      branchName: branch.toString(),
      customerName: customer.toString(),
      invoiceType: invoiceType.toString(),
      invoiceNumber: invoiceNumber.toString(),
    };
  }

  fetchData(params?: RequestQueryParamsModel): void {
    const { findAll } = useArPrepayment();
    this.loading = true;
    findAll(params)
      .then(res => {
        this.dtPrepayment = res;
      })
      .finally(() => {
        this.loading = false;
      });
  }

  buildParams(form: Partial<FormFilter>): RequestQueryParamsModel {
    const { findAllBy } = useArPrepayment();
    const {
      invoiceType,
      branchName,
      customerName,
      invoiceDate,
      invoiceNumber,
      status,
    } = form;
    const { page, limit } = this.pagination;
    const { filter } = findAllBy({
      invoiceType,
      branchName,
      customerName,
      invoiceDate,
      invoiceNumber,
      status,
    });
    const params = new RequestQueryParams();

    params.page = page - ONE;
    params.limit = limit;
    params.search = filter;

    return params;
  }

  updateQueryRoute(value: Record<string, string | null> = {}): void {
    this.$router.replace({
      name: "account-receivable.invoice.prepayment",
      query: {
        ...this.$route.query,
        ...value,
      },
    });
  }

  resetQueryRoute(): void {
    this.$router.replace({
      name: "account-receivable.invoice.prepayment",
      query: {},
    });
  }

  buildQueryRoute({
    branchName,
    customerName,
    invoiceDate,
    invoiceType,
    invoiceNumber,
    status,
  }: FormFilter): Record<string, string | undefined> {
    const { toAsOfDate } = useDate();
    const [start, end] = invoiceDate;
    return {
      invoiceNumber: invoiceNumber || undefined,
      invoiceType: invoiceType || undefined,
      status: status || undefined,
      branch: branchName || undefined,
      customer: customerName || undefined,
      from: start ? toAsOfDate(moment(start)) : undefined,
      to: end ? toAsOfDate(moment(end)) : undefined,
    };
  }

  handleFind(): void {
    this.pagination.reset();
    const params = this.buildParams(this.form);
    this.fetchData(params);
    const queryRoute = this.buildQueryRoute(this.form);
    this.updateQueryRoute({
      ...queryRoute,
      p: this.pagination.page.toString(),
    });
  }

  handleReset(): void {
    this.formModel.resetFields();
    this.resetQueryRoute();
  }

  onTableChange(pagination: APagination): void {
    const { current, pageSize } = pagination;

    this.pagination.page = current;

    this.pagination.page =
      pageSize !== this.pagination.limit ? FIRST_PAGE : current;
    this.pagination.limit = pageSize;

    const params = this.buildParams(this.form);
    this.fetchData(params);
    this.updateQueryRoute({
      p: this.pagination.page.toString(),
      l: this.pagination.limit.toString(),
    });
  }

  onChangeBranch(e?: Option<BranchWarehouseResponseDto>): void {
    this.form.branchName = e?.label || "";
  }

  onChangeCustomer(e?: Option<ListContactDataDTO>): void {
    this.form.customerName = e?.meta?.firstName || "";
  }

  onChangeInvoice(e?: Option<InvoicePrepaymentListResponseDto>): void {
    this.form.invoiceNumber = e?.label || "";
  }
}
